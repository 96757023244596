import React, { useRef, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import "./invoiceDetailPage.scss";
import InvoiceViewSection from "./InvoiceViewSection";
import InvoiceStatusAndAction from "./InvoiceStatusAndAction";

const InvoiceDetailPage = ({ accessToken, apiBaseName, ...props }) => {
  const invoiceRef = useRef(null);
  const [cancelled, setCancelled] = useState(false);

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Invoice Detail</Card.Title>
          {cancelled ? (
            <span className="badge bg-danger p-2 float-end">Cancelled</span>
          ) : null}
        </Card.Header>
      </Card>
      <Row style={{ height: "80%" }}>
        <Col lg={6} style={{ height: "70%" }}>
          <InvoiceViewSection innerRef={invoiceRef} {...props} />
        </Col>

        <Col
          lg={6}
          className="d-flex flex-column align-items-center justify-content-between"
        >
          <InvoiceStatusAndAction
            invoiceRef={invoiceRef}
            {...props}
            setCancelStatus={setCancelled}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

export default connect(mapStateToProps)(InvoiceDetailPage);
