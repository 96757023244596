import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { paymentMethodsChangeable } from "../../../../constants/payment-methods";

const Payment = ({ payment, setPayments }) => {
  const nonChangeAblePaymentModes = ["Package", "Prepaid", "Voucher", "Wallet"];
  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderRadius: "0.375rem",
    }),
  };

  const isChangeablePaymentMode = nonChangeAblePaymentModes.includes(
    payment.payment_mode
  );

  const handelPaymentModeChange = (paymentOption) => {
    setPayments((prev) => {
      return prev.map((pmnt) => {
        if (
          pmnt.payment_mode === payment.payment_mode &&
          !prev.some((pmnt) => pmnt.payment_mode === paymentOption.value)
        ) {
          return {
            ...pmnt,
            payment_mode: paymentOption.value,
          };
        } else return pmnt;
      });
    });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(Number(value))) {
      setPayments((prev) => {
        return prev.map((pmnt) => {
          if (pmnt.payment_mode === payment.payment_mode) {
            return {
              ...pmnt,
              amount: value,
            };
          } else return pmnt;
        });
      });
    } else return;
  };

  const handleDelete = () => {
    setPayments((prev) => {
      return prev.filter((pmnt) => pmnt.payment_mode !== payment.payment_mode);
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-lg-3">
        <Col sm={5} lg={5} className="mb-sm-3 mb-lg-0">
          <Select
            options={paymentMethodsChangeable}
            defaultValue={
              payment.payment_mode
                ? {
                    label: payment.payment_mode,
                    value: payment.payment_mode,
                  }
                : null
            }
            value={
              payment.payment_mode
                ? {
                    label: payment.payment_mode,
                    value: payment.payment_mode,
                  }
                : null
            }
            isDisabled={isChangeablePaymentMode}
            styles={customSelectStyles}
            onChange={handelPaymentModeChange}
            placeholder="Select method"
          />
        </Col>
        <Col sm={5} lg={5} className="mb-sm-3 mb-lg-0">
          <Form.Control
            name="amount"
            value={payment.amount}
            disabled={isChangeablePaymentMode}
            onChange={handleAmountChange}
            placeholder="Enter amount"
          />
        </Col>
        <Col sm={1} lg={1}>
          <span
            className="feather icon-x f-20 text-c-red"
            type="button"
            title="Delete"
            onClick={handleDelete}
          ></span>
        </Col>
      </Form.Group>
    </>
  );
};

export default Payment;
