import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import axios from "axios";
import * as Yup from "yup";
import Aux from "../../../../hoc/_Aux";
import Spinner from "react-bootstrap/Spinner";
import { setLastOpenedDate } from "../../../../store/user/user.actions";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DrawerCash = ({
  accessToken,
  defaultPath,
  setLastOpenedDate,
  apiBaseName,
}) => {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    return () => {
      controller.abort();
    };
  }, []);
  const initialValues = {
    drawerCash: "",
  };

  const validationSchema = Yup.object().shape({
    drawerCash: Yup.number("only numbers accepted").required(
      "Drawer cash is required"
    ),
  });

  const handleSkip = () => {
    setLastOpenedDate();
    return <Redirect push to={defaultPath} />;
  };

  const handleSubmit = async (values) => {
    const data = JSON.stringify({
      drawer_cash: values.drawerCash,
    });
    setloading(true);
    if (accessToken !== "") {
      await axios({
        method: "post",
        url: `${apiBaseName}/users/drawercash`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(function (response) {
          setloading(false);
          setLastOpenedDate();
          toast.success(
            `Drawer cash of Rs. ${values.drawerCash}  added successfully`
          );
          return <Redirect push to={defaultPath} />;
        })
        .catch(function (error) {
          console.log(error.response);
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Oops. Something went wrong.");
          }
          setloading(false);
        });
    } else {
      setloading(false);
    }
  };
  return (
    <Aux>
      <div className="card">
        <div className="card-body text-center">
          <div className="mb-4">
            <i className="feather icon-briefcase auth-icon" />
          </div>
          <h3 className="mb-4">Drawer Cash</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors }) => (
              <Form>
                <div className="input-group mb-3">
                  <Field
                    type="number"
                    id="number"
                    name="drawerCash"
                    className={`form-control ${
                      errors.drawerCash ? "is-invalid" : ""
                    }`}
                    placeholder="Drawer cash"
                    style={{ width: "100%" }}
                  />
                  <ErrorMessage name="drawerCash">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <button
                  className="btn-theme2 f-14 m-2"
                  type="button"
                  style={{ minWidth: "55px" }}
                  onClick={handleSkip}
                >
                  Skip
                </button>

                <button
                  className="btn-theme f-14 m-2"
                  type="submit"
                  style={{ minWidth: "55px" }}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner as="span" animation="border" size="sm" />
                  )}
                  {!loading && <span>Add</span>}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  defaultPath: state.reducer.defaultPath,
  apiBaseName: state.reducer.apiBaseName,
});

const mapDispatchToProps = (dispatch) => ({
  setLastOpenedDate: () => dispatch(setLastOpenedDate()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DrawerCash);
