import { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

function useFetch(url, method = "get", body = null, apiBaseName, accessToken) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = new AbortController();

  useEffect(() => {
    setLoading(true);
    axios({
      method: method,
      url: `${apiBaseName}/${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: body,
      signal: abortController.signal,
    })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setError(error);
        setLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [url]);

  return [data, loading, error];
}

export default useFetch;
