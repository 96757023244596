import { Staff_Action_Types } from "./staff.types";

const initialState = {
  staff: [],
  isLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    perPage: 10,
    to: null,
    total: null,
    from: null,
    controlledPageCount: 1,
  },
  filter: {
    searchString: "",
  },
  isUpdated: false,
  staffOptionList: [],
  staffDetailPageAccess: false,
};

const staffReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Staff_Action_Types.FETCH_STAFF_START:
      return {
        ...state,
        isLoading: true,
      };

    case Staff_Action_Types.FETCH_STAFF_SUCCESS:
      return {
        ...state,
        staff: payLoad.data,
        isLoading: false,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          to: payLoad.to,
          total: payLoad.total,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
      };

    case Staff_Action_Types.FETCH_STAFF_OPTION_LIST:
      return {
        ...state,
        staffOptionList: payLoad.map((staff) => ({
          value: staff.id,
          label: staff.employee_name,
        })),
        isLoading: false,
      };

    case Staff_Action_Types.FETCH_STAFF_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payLoad,
      };

    case Staff_Action_Types.SET_IS_UPDATED:
      return { ...state, isUpdated: !state.isUpdated };

    case Staff_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: payLoad,
        },
      };

    case Staff_Action_Types.SET_FILTER_SEARCH_STRING:
      return {
        ...state,
        filter: {
          searchString: payLoad,
        },
      };
    case Staff_Action_Types.SET_STAFF_DETAIL_PAGE_ACCESS:
      return {
        ...state,
        staffDetailPageAccess: payLoad,
      };

    default:
      return state;
  }
};

export default staffReducer;
