import "./user.actions";
import { USER_ACTION_TYPES } from "./user.types";

const initialState = {
  isLoggedIn: false,
  accessToken: "",
  day: new Date().getDate().toString(),
  lastOpenedDate: null,
  name: "",
  businessName: "",
  mobileNumber: "",
  email: "",
  staffAccess: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: !state.isLoggedIn,
      };
    case USER_ACTION_TYPES.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payLoad,
      };
    case USER_ACTION_TYPES.SET_USER_DETAILS:
      return {
        ...state,
        name: action.payLoad.first_name + action.payLoad.last_name,
        businessName: action.payLoad.business_name,
        mobileNumber: action.payLoad.mobile,
        email: action.payLoad.email,
      };
    case USER_ACTION_TYPES.SET_LAST_OPENED_DATE:
      return {
        ...state,
        lastOpenedDate: new Date().getDate().toString(),
      };
    case USER_ACTION_TYPES.DELETE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: "",
        isLoggedIn: false,
        name: "",
        businessName: "",
        mobileNumber: "",
        email: "",
      };

    case USER_ACTION_TYPES.SET_STAFF_ACCESS:
      return {
        ...state,
        staffAccess: action.payLoad,
      };

    default:
      return state;
  }
};

export default userReducer;
