import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { connect } from "react-redux";
import {
  changeIsLoggedIn,
  setAccessToken,
  setUserDetails,
} from "../../../../store/user/user.actions";
import Spinner from "react-bootstrap/Spinner";

import * as Yup from "yup";
import Aux from "../../../../hoc/_Aux";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SignInWithMobile = ({
  setAccessToken,
  changeisLoggedIn,
  apiBaseName,
  setUserDetails,
}) => {
  const [loading, setloading] = useState(false);
  const initialValues = {
    phoneNumber: "",
    password: "",
  };

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    const data = JSON.stringify({
      username: values.phoneNumber,
      password: values.password,
    });
    setloading(true);
    await axios({
      method: "post",
      url: `${apiBaseName}/users/login`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        setloading(false);
        toast.success(
          `Successfully logged in as ${response.data.data.user.first_name} ${response.data.data.user.last_name}`
        );
        setAccessToken(response.data.data.access_token);
        setUserDetails(response.data.data.user);
        changeisLoggedIn();
      })
      .catch(function (error) {
        setloading(false);
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Oops. Something went wrong.");
        }
      });
  };
  return (
    <Aux>
      <div className="card">
        <div className="card-body text-center">
          <div className="mb-4">
            <i className="feather icon-unlock auth-icon" />
          </div>
          <h3 className="mb-4">Sign In</h3>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors }) => {
                return (
                  <Form>
                    <div className="input-group mb-3">
                      <Field
                        type="poneNumber"
                        id="phoneNumber"
                        name="phoneNumber"
                        className={`form-control ${
                          errors.phoneNumber ? "is-invalid" : ""
                        }`}
                        placeholder="Phone number"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage name="phoneNumber">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="input-group mb-4">
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Password"
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage name="password">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <button className="btn-theme text-white f-14" type="submit">
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {!loading && <span>Sign In</span>}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Aux>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
  setUserDetails: (user) => dispatch(setUserDetails(user)),
  changeisLoggedIn: () => dispatch(changeIsLoggedIn()),
});
const mapStateToProps = (state) => ({
  apiBaseName: state.reducer.apiBaseName,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInWithMobile);
