import { Balance_Amounts_Action_Types } from "./balanceAmounts.types";
import axios from "axios"

export const fetchBalancesStart = () => ({
    type: Balance_Amounts_Action_Types.FETCH_BALANCES_START,
})

export const fetchBalancesSuccess = (balanceList) => ({
    type: Balance_Amounts_Action_Types.FETCH_BALANCES_SUCCESS,
    payLoad: balanceList
})

export const fetchBalancesFailed = (error) => ({
    type: Balance_Amounts_Action_Types.FETCH_BALANCES_FAILED,
    payLoad: error
})

export const setPageSize = (pageSize) => ({
    type: Balance_Amounts_Action_Types.SET_PAGE_SIZE,
    payLoad: pageSize
})

export const setisUpdated = () => ({
    type: Balance_Amounts_Action_Types.SET_IS_UPDATED
})

export const setFilterSearchString = (searchKey) => ({
    type: Balance_Amounts_Action_Types.SET_FILTER_SEARCH_STRING,
    payLoad: searchKey
})

export const fetchBalancesAsync = (pageIndex, pageSize) => async(dispatch, getState) => {
    const accessToken = getState().user.accessToken;
    const apiBaseName = getState().reducer.apiBaseName;
    const filter = getState().balances.filter;
    console.log(pageSize)
    dispatch(fetchBalancesStart())

    await axios({
        method: "post",
        url: `${apiBaseName}/balance-amounts/list?page=${
            pageIndex + 1
          }&limit=${pageSize}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
            searchKey: filter.searchKey
        }
      })
        .then(function (response) {
            dispatch(fetchBalancesSuccess(response.data))
        })
        .catch(function (error) {
            dispatch(fetchBalancesFailed(error))
        });  

}