export const paymentMethods = [
  { value: "Cash", label: "Cash" },
  { value: "UPI", label: "UPI" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Debit Card", label: "Debit Card" },
  { value: "NEFT", label: "NEFT" },
  { value: "Online", label: "Online" },
  { value: "Package", label: "Package" },
  { value: "Prepaid", label: "Prepaid" },
  { value: "Voucher", label: "Voucher" },
  { value: "Wallet", label: "Wallet" },
  { value: "Other", label: "Other" },
];

export const paymentMethodsChangeable = [
  { value: "Cash", label: "Cash" },
  { value: "UPI", label: "UPI" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Debit Card", label: "Debit Card" },
  { value: "NEFT", label: "NEFT" },
  { value: "Online", label: "Online" },
  { value: "Other", label: "Other" },
];
