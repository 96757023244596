import { USER_ACTION_TYPES } from "./user.types";

export const setAccessToken = (accessToken) => ({
  type: USER_ACTION_TYPES.SET_ACCESS_TOKEN,
  payLoad: accessToken,
});

export const deleteAccessToken = () => ({
  type: USER_ACTION_TYPES.DELETE_ACCESS_TOKEN,
});

export const changeIsLoggedIn = () => ({
  type: USER_ACTION_TYPES.SET_IS_LOGGED_IN,
});

export const setLastOpenedDate = () => ({
  type: USER_ACTION_TYPES.SET_LAST_OPENED_DATE,
});

export const setUserDetails = (user) => ({
  type: USER_ACTION_TYPES.SET_USER_DETAILS,
  payLoad: user,
});

export const setStaffAccess = (value) => ({
  type: USER_ACTION_TYPES.SET_STAFF_ACCESS,
  payLoad: value,
});
