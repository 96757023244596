import React, {useState, useEffect} from 'react'

const NoInternetConnection = (props) => {
    const [isOnline, setOnline] = useState(true);

    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    window.addEventListener('online', () => {
        setOnline(true)
        window.location.reload()
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    if(isOnline){
    return(
        props.children
    )
    } else {
        return(
            <div className='d-flex justify-content-center align-items-center flex-column' style={{height: "100vh", fontSize: "4rem"}}>
                <span className='feather icon-wifi-off text-secondary'></span>
                <h3 className='text-secondary'>No Internet Connection. Please try again later.</h3>
            </div>
        )
    }
}

export default NoInternetConnection