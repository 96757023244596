import axios from "axios";
import { Invoices_Action_Types } from "./invoices.types";

export const fetchInvoicesStart = () => ({
  type: Invoices_Action_Types.FETCH_INVOICES_START,
});

export const fetchInvoicesSuccess = (invoicesArray) => ({
  type: Invoices_Action_Types.FETCH_INVOICES_SUCCESS,
  payLoad: invoicesArray,
});

export const fetchInvoicesStats = (stats) => ({
  type: Invoices_Action_Types.FETCH_INVOICES_STATS,
  payLoad: stats,
});

export const fetchInvoicesFailed = (error) => ({
  type: Invoices_Action_Types.FETCH_INVOICES_FAILED,
  payLoad: error,
});

export const setPageSize = (pageSize) => ({
  type: Invoices_Action_Types.SET_PAGE_SIZE,
  payLoad: pageSize,
});

export const setisUpdated = () => ({
  type: Invoices_Action_Types.SET_IS_UPDATED,
});

export const setPageNumber = (pageNumber) => ({
  type: Invoices_Action_Types.SET_PAGE_NUMBER,
  payLoad: pageNumber,
});

export const setFilterSearchString = (searchString) => ({
  type: Invoices_Action_Types.SET_FILTER_SEARCH_STRING,
  payLoad: searchString,
});

export const setFilterFromDate = (date) => ({
  type: Invoices_Action_Types.SET_SEARCH_FILTER_FROM_DATE,
  payLoad: date,
});

export const setFilterToDate = (date) => ({
  type: Invoices_Action_Types.SET_SEARCH_FILTER_TO_DATE,
  payLoad: date,
});

export const setFilterPaymentMode = (paymentMode) => ({
  type: Invoices_Action_Types.SET_SEARCH_FILTER_PAYMENT_MODE,
  payLoad: paymentMode,
});

export const resetInvoicesState = () => ({
  type: Invoices_Action_Types.RESET_STATE,
});

export const setCustomerMobile = (customerMobile) => ({
  type: Invoices_Action_Types.SET_CUSTOMER_MOBILE,
  payLoad: customerMobile,
});

export const resetCustomerMobile = () => ({
  type: Invoices_Action_Types.RESET_CUSTOMER_MOBILE,
});

export const setAppointmentId = (appointmentId) => ({
  type: Invoices_Action_Types.SET_APPOINTMENT_ID,
  payLoad: appointmentId,
});

export const resetAppointmentId = () => ({
  type: Invoices_Action_Types.RESET_APPOINTMENT_ID,
});

export const fetchInvoicesAsync =
  (pageIndex, pageSize, type) => async (dispatch, getState) => {
    const accessToken = getState().user.accessToken;
    const apiBaseName = getState().reducer.apiBaseName;
    const { perPage } = getState().invoices.pagination;
    const { filter } = getState().invoices;
    const limit = pageSize === "total" ? perPage : pageSize;

    dispatch(fetchInvoicesStart());
    await axios({
      method: "post",
      url: `${apiBaseName}/orders-main/list?page=${
        pageIndex + 1
      }&limit=${limit}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        search_key: filter.searchString,
        from_date: filter.from,
        to_date: filter.to,
        type: type ? type : "",
        payment_mode: filter.paymentMode,
      },
    })
      .then(function (response) {
        console.log(response.data);
        if (type === "total") {
          dispatch(fetchInvoicesStats(response.data));
        } else {
          dispatch(fetchInvoicesSuccess(response.data));
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(fetchInvoicesFailed(error));
      });
  };

export const fetchCancelledinvoicesAsync =
  (pageIndex, pageSize) => async (dispatch, getState) => {
    const accessToken = getState().user.accessToken;
    const apiBaseName = getState().reducer.apiBaseName;
    const { perPage } = getState().invoices.pagination;
    const { filter } = getState().invoices;
    const limit = pageSize;
    console.log(limit);

    dispatch(fetchInvoicesStart());
    await axios({
      method: "post",
      url: `${apiBaseName}/orders-main/cancelled-invoices-list?page=${
        pageIndex + 1
      }&limit=${limit}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        search_key: filter.searchString,
        from_date: filter.from,
        to_date: filter.to,
        payment_mode: filter.paymentMode,
      },
    })
      .then(function (response) {
        console.log(response.data);
        dispatch(fetchInvoicesSuccess(response.data.data));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(fetchInvoicesFailed(error));
      });
  };
