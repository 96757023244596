import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import reducer from "./reducer";
import clientsReducer from "./clients/clients.reducer";
import staffReducer from "./staff/staff.reducer";
import serviceReducer from "./services/services.reducer";
import { persistReducer } from "redux-persist";
import productReducer from "./products/products.reducer";
import storage from "redux-persist/lib/storage";
import invoicesReducer from "./invoices/invoices.reducer";
import balanceAmountsReducer from "./balanceAmounts/balanceAmounts.reducer";
import advanceAmountsReducer from "./advanceAmounts/advanceAmounts.reducer";
import vouchersReducer from "./vouchers/vouchers.reducer";
import packagesReducer from "./packages/packages.reducer";
import membershipsReducer from "./memberships/memberships.reducer";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  reducer: reducer,
  user: userReducer,
  clients: clientsReducer,
  staff: staffReducer,
  services: serviceReducer,
  products: productReducer,
  invoices: invoicesReducer,
  balances: balanceAmountsReducer,
  advances: advanceAmountsReducer,
  vouchers: vouchersReducer,
  packages: packagesReducer,
  memberships: membershipsReducer,
});

export default persistReducer(persistConfig, rootReducer);
