import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import useFetch from "../../../../hooks/useFetch";
import { connect } from "react-redux";
import Payment from "./Payment";
import axios from "axios";
import { toast } from "react-toastify";
import { setisUpdated } from "../../../../store/invoices/invoices.actions";

const ChangePaymentAmount = ({
  invoiceId,
  apiBaseName,
  accessToken,
  setInvoiceUpdated,
  openModal,
}) => {
  const [payments, setPayments] = useState(null);
  const [totalPaymentsAmount, settotalPaymentsAmount] = useState(0);
  const [data, loading, error] = useFetch(
    `orders-main/payment-modes/${invoiceId}`,
    "",
    null,
    apiBaseName,
    accessToken
  );
  const [updatingPayments, setUpdatingPayments] = useState(false);
  useEffect(() => {
    if (data) {
      setPayments(data);
      const paymentTotal = data.reduce(
        (acc, payment) => Number(payment.amount) + acc,
        0
      );
      settotalPaymentsAmount(paymentTotal);
    }
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredNonEmptyPayments = payments.filter((payment) => {
      if (payment.payment_mode !== null || payment.amount !== "") {
        return payment;
      }
    });
    const paymentModes = filteredNonEmptyPayments.map((payment) => {
      return payment.payment_mode;
    });
    const paymentAmounts = filteredNonEmptyPayments.map(
      (payment) => payment.amount
    );
    const data = JSON.stringify({
      payment_modes: paymentModes,
      payment_amounts: paymentAmounts,
    });

    const paymentTotal = paymentAmounts.reduce(
      (acc, amount) => Number(amount) + acc,
      0
    );
    if (paymentTotal < totalPaymentsAmount) {
      toast.error(
        "Selected payments total must be equal to Actual Paid Amount",
        {
          autoClose: 3000,
        }
      );
    }
    setUpdatingPayments(true);
    await axios({
      method: "post",
      url: `${apiBaseName}/orders-main/payment-modes/update/${invoiceId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    })
      .then(function (response) {
        console.log(response);
        setUpdatingPayments(false);
        toast.success("Payments changed successfully");
        setInvoiceUpdated();
        openModal(false);
      })
      .catch(function (error) {
        console.log(error);
        setUpdatingPayments(false);
      });
  };

  const addPayment = () => {
    setPayments((prev) => {
      return [...prev, { payment_mode: null, amount: "" }];
    });
  };

  const LoadingData = (
    <div className="d-flex align-items-center justify-content-center">
      <Spinner as="span" animation="border" />
    </div>
  );

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Change Payment Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "150px" }}>
        {payments && (
          <div className="py-2 my-2 d-flex justify-content-between align-items-center">
            <div>
              <span>Actual Paid Amount :</span>
              <span className="f-w-600"> {totalPaymentsAmount}</span>
            </div>
            <button className="btn-theme" type="button" onClick={addPayment}>
              Add Payment
            </button>
          </div>
        )}
        {loading ? (
          LoadingData
        ) : payments ? (
          payments.map((payment) => (
            <Payment payment={payment} setPayments={setPayments} />
          ))
        ) : (
          <div>No payments to show</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn-theme2">
          Cancel
        </button>
        <button
          type="button"
          className="btn-theme"
          onClick={handleSubmit}
          disabled={updatingPayments}
        >
          {updatingPayments ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            "Apply Changes"
          )}
        </button>
      </Modal.Footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

const mapDispatchToProps = (dispatch) => ({
  setInvoiceUpdated: () => dispatch(setisUpdated()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePaymentAmount);
