export const Staff_Action_Types = {
  FETCH_STAFF_START: "staff/FETCH_STAFF_START",
  FETCH_STAFF_SUCCESS: "staff/FETCH_STAFF_SUCCESS",
  FETCH_STAFF_OPTION_LIST: "staff/FETCH_STAFF_OPTION_LIST",
  FETCH_STAFF_FAILED: "staff/FETCH_STAFF_FAILED",
  SET_PAGE_SIZE: "staff/SET_PAGE_SIZE",
  SET_IS_UPDATED: "staff/SET_IS_UPDATED",
  SET_FILTER_SEARCH_STRING: "staff/SET_FILTER_SEARCH_STRING",
  SET_STAFF_DETAIL_PAGE_ACCESS: "staff/SET_STAFF_DETAIL_PAGE_ACCESS",
};
