import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { connect } from "react-redux";
import { setisUpdated } from "../../../store/invoices/invoices.actions";
import { toast } from "react-toastify";

const CancelInvoice = ({
  setOpenModal,
  invoiceId,
  accessToken,
  apiBaseName,
  setInvoiceUpdated,
}) => {
  const [isDeleting, setisDeleting] = useState(false);

  const handleDelete = async () => {
    setisDeleting(true);
    await axios({
      method: "post",
      url: `${apiBaseName}/orders-main/cancel-invoice/${invoiceId}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(function (response) {
        console.log(response);
        setInvoiceUpdated();
        setisDeleting(false);
        toast.success("Invoice cancelled");
        setOpenModal(false);
      })
      .catch(function (error) {
        console.log(error);
        setisDeleting(false);
      });
  };

  return (
    <>
      <Modal.Body>
        <div className="p-3 text-center position-relative">
          <div className="text-end">
            <button
              className="btn-close"
              type="button"
              onClick={() => setOpenModal(false)}
            ></button>
          </div>
          <FontAwesomeIcon
            icon={faRectangleXmark}
            style={{ fontSize: "80px" }}
            className="text-danger opacity-75"
          />
          <p className="f-28 p-2 text-secondary">
            Are you sure you want to cancel the invoice ?
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="btn-theme2"
              onClick={() => setOpenModal(false)}
              type="button"
            >
              No
            </button>
            <button
              className="btn-theme"
              onClick={handleDelete}
              type="button"
              disabled={isDeleting}
            >
              {isDeleting ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
});

const mapDispatchToProps = (dispatch) => ({
  setInvoiceUpdated: () => dispatch(setisUpdated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelInvoice);
