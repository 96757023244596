import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";

import "./SignIn1.styles.scss";
import SignInWithMobile from "./SignInWithMobile/SignInWithMobile";
import DrawerCash from "./DrawerCash/DrawerCash";

const SignIn1 = (props) => {
  const { isLoggedIn, lastOpenedDate, defaultPath } = props;

  const day = new Date().getDate().toString();

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          {isLoggedIn ? (
            day !== lastOpenedDate ? (
              <DrawerCash props={props} />
            ) : (
              <Redirect path to={defaultPath} />
            )
          ) : (
            <SignInWithMobile />
          )}
        </div>
      </div>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  defaultPath: state.reducer.defaultPath,
  isLoggedIn: state.user.isLoggedIn,
  lastOpenedDate: state.user.lastOpenedDate,
});

export default connect(mapStateToProps)(SignIn1);
