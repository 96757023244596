import { Advance_Action_Types } from "./advanceAmounts.types";
import moment from "moment"

const initialState = {
  advanceAmountsList: [],
  isLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    lastPage: 1,
    perPage: 5,
    from: null,
    to: null,
    total: null,
    controlledPageCount: 1,
  },
  filter: {
    from: "",
    to: "",
    searchKey: "",
  },
};

const advanceAmountsReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Advance_Action_Types.FETCH_ADVANCES_START:
      return {
        ...state,
        isLoading: true,
      };

    case Advance_Action_Types.FETCH_ADVANCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        advanceAmountsList: payLoad.data,
        pagination: {
          pageNumber: payLoad.current_page,
          lastPage: payLoad.last_page,
          perPage: payLoad.per_page,
          from: payLoad.from,
          to: payLoad.to,
          total: payLoad.total,
          controlledPageCount: payLoad.last_page,
        },
        error: null
      };

    case Advance_Action_Types.FETCH_ADVANCES_FAILED:
        return {
            ...state,
            error: null
        }

    case Advance_Action_Types.SET_FILTER_FROM_DATE:
        return {
            ...state,
            filter: {
                ...state.filter,
                from: payLoad ? new moment(payLoad).format("YYYY-MM-DD") : null
            }
        }

    case Advance_Action_Types.SET_FILTER_TO_DATE:
        return {
            ...state,
            filter: {
                ...state.filter,
                to: payLoad ? new moment(payLoad).format("YYYY-MM-DD") : null
            }
        }

    case Advance_Action_Types.SET_FILTER_SEARCH_STRING:
        return {
            ...state,
            filter: {
                ...state.filter,
                searchKey: payLoad
            }
        }

    default:
      return state;
  }
};

export default advanceAmountsReducer
