import React, { useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import SplitServiceAmountModal from "./SplitServiceAmountModal";
import { Card, Dropdown, Modal, Form, Spinner, Col } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import PaymentDueModal from "./PaymentDueModal";
import html2pdf from "html2pdf.js";
import CancelInvoice from "./CancelInvoice";
import ChangePaymentAmount from "./ChangePaymentAmountModal/ChangePaymentAmount";

const InvoiceStatusAndAction = ({
  invoiceRef,
  match,
  accessToken,
  apiBaseName,
  invoiceUpdated,
  setCancelStatus,
}) => {
  const [openSplitServiceAmountModal, setopenSplitServiceAmountModal] =
    useState(false);
  const [fetchingOrderDetail, setfetchingOrderDetail] = useState(false);
  const [orderDetail, setorderDetail] = useState(null);
  const [isUpdated, setisUpdated] = useState(false);
  const [dueModalOpen, setdueModalOpen] = useState(false);
  const [loadingInvoicedetail, setloadingInvoicedetail] = useState(false);
  const [invoiceDetail, setinvoiceDetail] = useState(null);
  const [cancelInvoiceModal, setcancelInvoiceModal] = useState(false);
  const [changePaymentModel, setchangePaymentModel] = useState(false);
  const [email, setemail] = useState("");

  const abortController = new AbortController();

  useEffect(() => {
    setfetchingOrderDetail(true);
    axios({
      method: "get",
      url: `${apiBaseName}/orders-main/order-details/${match.params.id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
      .then(function (response) {
        console.log(response.data.data);
        setfetchingOrderDetail(false);
        setorderDetail(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        setfetchingOrderDetail(false);
      });

    return () => {
      abortController.abort();
    };
  }, [isUpdated, invoiceUpdated]);

  useEffect(() => {
    setloadingInvoicedetail(true);
    axios({
      method: "get",
      url: `${apiBaseName}/orders-main/order-full-detail/${match.params.id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
      .then(function (response) {
        console.log(response.data.data);
        setinvoiceDetail(response.data.data);
        setemail(response.data.data.Customer.customer_email);
        setCancelStatus(response.data.data?.Order[0]?.is_cancelled);
        setloadingInvoicedetail(false);
      })
      .catch(function (error) {
        setloadingInvoicedetail(false);
      });
    return () => {
      abortController.abort();
    };
  }, [invoiceUpdated]);

  const handleDownload = () => {
    const content = document.getElementById("invoice-content");

    const pdfOptions = {
      margin: 10,
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(content).set(pdfOptions).toPdf().save("invoice.pdf");
  };

  return (
    <>
      <Card style={{ minWidth: "100%", flex: "50%" }}>
        {loadingInvoicedetail ? (
          <div
            style={{ minHeight: "30%" }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <Spinner animation="border" />
          </div>
        ) : invoiceDetail ? (
          <>
            <Card.Header className="border-bottom">
              <Card.Title as="h5">{`${invoiceDetail.Customer.first_name} ${
                invoiceDetail.Customer.last_name
                  ? invoiceDetail.Customer.last_name
                  : ""
              }`}</Card.Title>
              <Dropdown
                className="d-inline float-end"
                style={{ cursor: "pointer" }}
              >
                <Dropdown.Toggle
                  as="span"
                  id="dropdown-autoclose-true"
                  className="feather icon-more-vertical f-16"
                  drop="end"
                ></Dropdown.Toggle>

                <Dropdown.Menu className="p-0" style={{ margin: 0 }}>
                  <Dropdown.Item
                    href="#"
                    onClick={() => setchangePaymentModel(true)}
                  >
                    Change Payment Amount
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => setcancelInvoiceModal(true)}
                  >
                    Cancel Invoice
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => setopenSplitServiceAmountModal(true)}
                  >
                    Split Service Amount
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body>
              {invoiceDetail.balance?.balance_amount ? (
                <div
                  className="d-flex justify-content-center align-items-center flex-column"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center flex-column bg-c-red p-2 opacity-75 mb-2 shadow"
                  >
                    <span className="feather icon-alert-circle f-40 text-c-white f-w-600"></span>
                    <span className="f-20 text-c-white f-w-600 text-center">
                      Payment Due
                    </span>
                  </div>
                  <button
                    className="btn-theme2 f-14"
                    title="Pay due"
                    onClick={() => setdueModalOpen(true)}
                  >
                    Pay Due
                  </button>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center flex-column"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center flex-column bg-info p-2 opacity-75 shadow"
                  >
                    <span className="feather icon-check f-40 text-c-white f-w-600"></span>
                    <span className="f-20 text-c-white f-w-600 text-center">
                      Fully Paid
                    </span>
                  </div>
                </div>
              )}
            </Card.Body>

            <Card.Footer className="d-flex justify-content-between">
              <button
                className="btn-outline-theme"
                title="Download invoice"
                onClick={handleDownload}
              >
                Download
              </button>
              <ReactToPrint
                trigger={() => (
                  <button className="btn-outline-theme" title="Print invoice">
                    Print
                  </button>
                )}
                content={() => invoiceRef.current}
              />
              <Form.Control
                type="email"
                style={{ width: "auto" }}
                className="d-inline-block m-r-10"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              ></Form.Control>
              <button className="btn-outline-theme" title="Email invoice">
                Email Invoice
              </button>
            </Card.Footer>
          </>
        ) : (
          <div
            style={{ height: "100%" }}
            className="d-flex justify-content-center align-items-center"
          >
            No order detail to show
          </div>
        )}
      </Card>
      <Card style={{ minWidth: "100%", flex: "50%" }}>
        <Card.Header>
          <Card.Title as="h5">Payments</Card.Title>
        </Card.Header>
        <Card.Body>
          {loadingInvoicedetail ? (
            <div
              style={{ minHeight: "30%" }}
              className="d-flex justify-content-center align-items-center flex-column"
            >
              <Spinner animation="border" />
            </div>
          ) : invoiceDetail ? (
            invoiceDetail.payments.map((payment) => (
              <Card>
                <div className="d-flex p-2 justify-content-between align-items-center">
                  <span className="f-16">{payment.type}</span>
                  <span className="f-22">&#x20b9;{payment.amount}</span>
                </div>
              </Card>
            ))
          ) : (
            "No paymnets available"
          )}
        </Card.Body>
      </Card>

      <Modal
        show={openSplitServiceAmountModal}
        onHide={() => setopenSplitServiceAmountModal(false)}
        backdrop="static"
        keyboard="false"
        centered
      >
        <SplitServiceAmountModal
          serviceArray={orderDetail}
          setModalOpen={setopenSplitServiceAmountModal}
          setisUpdated={setisUpdated}
          isUpdated={isUpdated}
        />
      </Modal>
      <Modal
        show={dueModalOpen}
        onHide={() => setdueModalOpen(false)}
        backdrop="static"
        keyboard="false"
        size="lg"
        centered
      >
        <PaymentDueModal
          setdueModalOpen={setdueModalOpen}
          balanceDetail={invoiceDetail ? invoiceDetail.balance : null}
        />
      </Modal>
      <Modal
        show={cancelInvoiceModal}
        onHide={() => setcancelInvoiceModal(false)}
        backdrop="static"
        keyboard="false"
        centered
      >
        <CancelInvoice
          setOpenModal={setcancelInvoiceModal}
          invoiceId={match.params.id}
        />
      </Modal>
      <Modal
        show={changePaymentModel}
        onHide={() => setchangePaymentModel(false)}
        backdrop="static"
        keyboard="false"
        centered
      >
        <ChangePaymentAmount
          invoiceId={match.params.id}
          openModal={setchangePaymentModel}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accessToken,
  apiBaseName: state.reducer.apiBaseName,
  invoiceUpdated: state.invoices.isUpdated,
});

export default connect(mapStateToProps)(InvoiceStatusAndAction);
