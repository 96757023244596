import { Invoices_Action_Types } from "./invoices.types";
import moment from "moment";

const initialState = {
  appointmentId: "",
  customerMobile: "",
  invoices: [],
  isLoading: false,
  error: null,
  pagination: {
    pageNumber: 1,
    perPage: 10,
    lastPage: 1,
    to: null,
    from: null,
    total: null,
    controlledPageCount: 1,
  },
  filter: {
    searchString: "",
    from: null,
    to: null,
    type: "",
    paymentMode: "",
  },
  total: 0,
  count: 0,
  isUpdated: false,
};

const invoicesReducer = (state = initialState, action = {}) => {
  const { type, payLoad } = action;

  switch (type) {
    case Invoices_Action_Types.FETCH_INVOICES_START:
      return {
        ...state,
        isLoading: true,
      };

    case Invoices_Action_Types.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: payLoad.data,
        isLoading: false,
        pagination: {
          ...state.pagination,
          pageNumber: payLoad.current_page,
          perPage: payLoad.per_page,
          lastPage: payLoad.last_page,
          total: payLoad.total,
          to: payLoad.to,
          from: payLoad.from,
          controlledPageCount: payLoad.last_page,
        },
        error: null,
      };

    case Invoices_Action_Types.FETCH_INVOICES_STATS:
      return {
        ...state,
        isLoading: false,
        total: payLoad.total,
        count: payLoad.total_count,
        error: null,
      };

    case Invoices_Action_Types.FETCH_INVOICES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payLoad,
      };

    case Invoices_Action_Types.SET_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          perPage: payLoad,
        },
      };

    case Invoices_Action_Types.SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: !state.isUpdated,
      };

    case Invoices_Action_Types.SET_PAGE_NUMBER:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payLoad,
        },
      };

    case Invoices_Action_Types.SET_FILTER_SEARCH_STRING:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchString: payLoad,
        },
      };

    case Invoices_Action_Types.SET_SEARCH_FILTER_FROM_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          from: payLoad ? new moment(payLoad).format("YYYY-MM-DD") : null,
        },
      };

    case Invoices_Action_Types.SET_SEARCH_FILTER_TO_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          to: payLoad ? new moment(payLoad).format("YYYY-MM-DD") : null,
        },
      };

    case Invoices_Action_Types.SET_SEARCH_FILTER_PAYMENT_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentMode: payLoad,
        },
      };

    case Invoices_Action_Types.RESET_STATE:
      return initialState;

    case Invoices_Action_Types.SET_CUSTOMER_MOBILE:
      return {
        ...state,
        customerMobile: payLoad,
      };

    case Invoices_Action_Types.RESET_CUSTOMER_MOBILE:
      return {
        ...state,
        customerMobile: "",
      };

    case Invoices_Action_Types.SET_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: payLoad,
      };

    case Invoices_Action_Types.RESET_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: "",
      };

    default:
      return state;
  }
};

export default invoicesReducer;
