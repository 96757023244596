import React, { Suspense } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import "../assets/scss/style.scss";
import { ToastContainer } from "react-toastify";
import NoInternetConnection from "./layout/NoInternetConnection";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const App = ({ isLoggedIn, lastOpenedDate }) => {
  const day = new Date().getDate().toString();
  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  return (
    <Aux>
      <ToastContainer autoClose={1200} />
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <NoInternetConnection>
            <Switch>
              {menu}
              <Route
                path="/"
                render={() =>
                  isLoggedIn === true ? (
                    day !== lastOpenedDate ? (
                      <Redirect to="/auth/signin-1" />
                    ) : (
                      <AdminLayout />
                    )
                  ) : (
                    <Redirect to="/auth/signin-1" />
                  )
                }
              />
            </Switch>
          </NoInternetConnection>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  lastOpenedDate: state.user.lastOpenedDate,
});

export default withRouter(connect(mapStateToProps)(App));
