import {
  faCoins,
  faLayerGroup,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";

export default {
  items: [
    {
      id: "navigation",
      title: "Navigation",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          // icon: 'feather icon-home',
          ficon: faHouse,
          breadcrumbs: false,
        },
        {
          id: "clients-page",
          title: "Clients",
          type: "item",
          url: "/clients",
          ficon: faUsersLine,
          // icon: 'feather icon-users'
        },
        {
          id: "invoice-page",
          title: "Invoice",
          type: "item",
          url: "/invoice",
          ficon: faReceipt,
          // icon: 'feather icon-clipboard',
          breadcrumbs: false,
        },
        {
          id: "staff-page",
          title: "Staff",
          type: "item",
          url: "/staff",
          // icon: 'feather icon-user',
          ficon: faUserTie,
          breadcrumbs: false,
        },
        {
          id: "inventory-page",
          title: "Inventory",
          type: "item",
          url: "/inventory",
          ficon: faBook,
          // icon: 'feather icon-book',
          breadcrumbs: false,
        },
        {
          id: "members-page",
          title: "Members",
          type: "item",
          url: "/members",
          ficon: faPeopleRoof,
          breadcrumbs: false,
        },
        {
          id: "Cashback & Loyalty",
          title: "Cashback & Loyalty",
          type: "item",
          url: "/cashback&loyalty",
          ficon: faCoins,
          breadcrumbs: false,
        },
        {
          id: "templates-page",
          title: "Templates",
          type: "item",
          url: "/templates",
          ficon: faLayerGroup,
          breadcrumbs: false,
        },
        {
          id: "reports-page",
          title: "Reports",
          type: "item",
          url: "/reports",
          // icon: 'feather icon-book',
          ficon: faChartColumn,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "settings-navigation",
      title: "settings",
      type: "group",
      icon: " icon-settings",
      children: [
        {
          id: "settings",
          title: "Settings",
          type: "collapse",
          icon: "feather icon-settings",
          children: [
            {
              id: "manage-feedback",
              title: "Manage Feedback",
              type: "item",
              url: "/settings/manage-feedback",
            },
            {
              id: "sms-setting",
              title: "SMS Setting",
              type: "item",
              url: "/settings/sms-setting",
            },
            {
              id: "other-settings",
              title: "Other Settings",
              type: "item",
              url: "/settings/other-setting",
            },
            {
              id: "data-import",
              title: "Data Import",
              type: "item",
              url: "/settings/data-import",
            },
            {
              id: "invoice-setting",
              title: "Invoice Setting",
              type: "item",
              url: "/settings/invoice-setting",
            },
            {
              id: "manage-expenses",
              title: "Manage Expenses",
              type: "item",
              url: "/settings/manage-expenses",
            },
            {
              id: "manage-taxes",
              title: "Manage Taxes",
              type: "item",
              url: "/settings/manage-taxes",
            },
            {
              id: "vouchers-prepaid-and-giftcards",
              title: "Vouchers Prepaids and Giftcards",
              type: "item",
              url: "/settings/vouchers-prepaid-and-giftcards",
            },
            {
              id: "packages",
              title: "Packages",
              type: "item",
              url: "/settings/packages",
            },
            {
              id: "memberships",
              title: "Memberships",
              type: "item",
              url: "/settings/memberships",
            },
          ],
        },
      ],
    },
  ],
};
